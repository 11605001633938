import ContentHeader from "../../Modules/Main/ContentHeader/ContentHeader.vue";
import BreadcrumbItem from "../../Modules/Main/BreadcrumbItem/BreadcrumbItem.vue";
import Content from "../../Modules/Main/Content/Content.vue";
import { mapGetters, mapActions } from "vuex";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VueMultiselect from "@/Components/vue-multiselect/src";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import CurrencyInput from "../../Components/CurrencyInput/CurrencyInput.vue";
import {
  BILL_LIST_GETTER,
  BILLS_PAGINATED_DATA_GETTER,
  BILL_GETTER,
  NEW_BILL_GETTER,
  FIRST_TIME_LOADED_GETTER,
  IS_LOADING_ALL_GETTER,
  IS_LOADING_GETTER,
  IS_CREATING_GETTER,
  CREATED_DATA_GETTER,
  IS_UPDATING_GETTER,
  UPDATED_DATA_GETTER,
  ERRORS_GETTER,
  FETCH_ALL_BILLS_ACTION,
  FETCH_DETAIL_BILL_ACTION,
  STORE_BILL_ACTION,
  UPDATE_BILL_ACTION,
  DELETE_BILL_ACTION,
  REMOVE_FILE_ACTION,
  ADD_BILL_INPUT_ACTION,
  UPDATE_BILL_INPUT_ACTION,
  SET_ERRORS_ACTION,
} from "@/Store/Modules/Bill/constants";
import {
  FETCH_ALL_USERS_ACTION,
  USER_LIST_GETTER,
} from "../../Store/Modules/User/constants";
import {
  DEPARTMENT_LIST_GETTER,
  FETCH_ALL_DEPARTMENTS_ACTION,
} from "../../Store/Modules/Department/constants";
import {
  SUPPLIER_LIST_GETTER,
  FETCH_ALL_SUPPLIERS_ACTION,
} from "../../Store/Modules/Supplier/constants";
import { RESPONSIBLE_PERSON_LIST_GETTER } from "../../Store/Modules/ResponsiblePerson/constants";
import { BILL_PERMISSIONS } from "../../Constants/permissions";
import { PROCUREMENT_OFFICER_LIST_GETTER } from "../../Store/Modules/ProcurementOfficer/constants";
// import { canGivenRolesHaveAccess } from "../../Roles/role.logic";
import { ROLES } from "../../Roles/roles";
// import XLSX from 'xlsx';
import * as XLSX from "xlsx";

export default {
  name: "Bills",
  components: {
    "content-header": ContentHeader,
    "breadcrumb-item": BreadcrumbItem,
    "page-content": Content,
    VPagination,
    VueMultiselect,
    flatPickr,
    "app-currency-input": CurrencyInput,
  },
  data() {
    return {
      showRequiredMessageForComment: false,
      showRequiredMessageForFile: false,
      approvalComment: "",
      approvalFile: null,
      modalAddActive: false,
      modalInfoActive: false,
      modalEditActive: false,
      modalAtthacmentsActive: false,
      modalRequestActive: false,
      pagination: {
        total: 0,
        page: 1,
      },
      query: {
        page: 1,
        search: {
          status: { id: 7, name: "All" },
          // status: { id: [1,2,3,4], name: "ALL" },
          // status: { id: 1, name: 'Requested' },
          sort: "DESC",
          orderBy: { id: "id", name: "Id" },
          text: null,
        },
      },
      selectNotifyDay: null,
      editNotifyDay: null,
      search: false,
      daysList: ["15", "30", "45", "60", "90", "120", "160", "180"],
      statusList: [
        { name: "REQUESTED", id: 1 },
        { name: "PENDING", id: 2 },
        { name: "APPROVED FROM CEO", id: 3 },
        { name: "CANCELED", id: 4 },
        { name: "APPROVED FROM ADMIN", id: 5 },
        { name: "Printed & Closed", id: 6 },
        { name: "ALL (TE GJITHA)", id: 7 },
      ],
      stepList: [
        { name: "Step 1", id: 1 },
        { name: "Step 2", id: 2 },
        { name: "Step 3", id: 3 },
        { name: "Step 4", id: 4 },
        { name: "Step 5", id: 5 },
        { name: "Step 6", id: 6 },
      ],
      searchStatusList: [
        // { id: 'ALL', name: 'ALL' },
        { name: "TE GJITHA", id: 7 },
        { name: "REQUESTED", id: 1 },
        { name: "PENDING", id: 2 },
        { name: "APPROVED FROM CEO", id: 3 },
        { name: "CANCELED", id: 4 },
        { name: "APPROVED FROM ADMIN", id: 5 },
        { name: "Printed & Closed", id: 6 },
      ],
      sortList: ["ASC", "DESC"],
      orderByList: [
        { id: "id", name: "Id" },
        { id: "bill_no", name: "Bill Number" },
        { id: "name", name: "Name" },
        { id: "created_by", name: "Porositi" },
        { id: "supplier", name: "Supplier" },
        { id: "value", name: "Value" },
      ],

      today: new Date(),
      config: {
        wrap: true,
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      priceConfig: {
        currency: "EUR",
        valueRange: { min: 1, max: 1000000000 },
        hideCurrencySymbolOnFocus: false,
      },
      canShowAll: false,
      canShow: false,
      canAdd: false,
      canRequest: false,
      canEdit: false,
      canDelete: false,
      canApprove: false,
      canPrintAll: false,
      canBillAttachments: false,
      labelFilesNameInsert: "Choose a file",
      labelFilesName: "Choose a file",
      labelFilesNameRequest: "Choose a file",
      clicked: false,
      allRoles: ROLES,
      billsEdited: [],
      cE: false,
    };
  },
  // mounted() {
  //   // this.fetchData();
  //   document.addEventListener("click", this.handleOutsideClick);
  // },
  // beforeUnmount() {
  //   // Remove the global click event listener when the component is about to be unmounted
  //   document.removeEventListener("click", this.handleOutsideClick);
  // },
  computed: {
    ...mapGetters("bills", {
      bill: BILL_GETTER,
      newBill: NEW_BILL_GETTER,
      billList: BILL_LIST_GETTER,
      billsPaginatedData: BILLS_PAGINATED_DATA_GETTER,
      createdData: CREATED_DATA_GETTER,
      updatedData: UPDATED_DATA_GETTER,
      isLoadingAll: IS_LOADING_ALL_GETTER,
      firstTimeLoaded: FIRST_TIME_LOADED_GETTER,
      isLoading: IS_LOADING_GETTER,
      isCreating: IS_CREATING_GETTER,
      isUpdating: IS_UPDATING_GETTER,
      errors: ERRORS_GETTER,
      billApprove: "billApprove",
      billCancel: "billCancel",
    }),

    ...mapGetters("users", {
      userList: USER_LIST_GETTER,
    }),

    ...mapGetters("departments", {
      departmentList: DEPARTMENT_LIST_GETTER,
    }),
    ...mapGetters("suppliers", {
      supplierList: SUPPLIER_LIST_GETTER,
    }),

    ...mapGetters("responsible_person", {
      responsible_personList: RESPONSIBLE_PERSON_LIST_GETTER,
    }),

    ...mapGetters("procurement_officer", {
      procurement_officerList: PROCUREMENT_OFFICER_LIST_GETTER,
    }),

    isModalActive() {
      return this.$store.getters["ui/modalActive"];
    },

    selectStep: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["step"] != null) {
            return this.newBill.step;
          }
        }
        return null;
      },
      set: function (step) {
        this.addStepForBillAction(step);
      },
    },

    selectBillType: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["bill_type"] != null) {
            return this.newBill.bill_type;
          }
        }
        return null;
      },
      set: function (bill_type) {
        this.addBillTypeForBillAction(bill_type);
      },
    },
    selectCompany: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["company"] != null) {
            return this.newBill.company;
          }
        }
        return null;
      },
      set: function (company) {
        if (company != null) {
          this.addCompanyForBillAction(company);
          this.getDepartmentByCompanyId(company.id);
        }
        this.setDepartmentForCompanyNullAction();
      },
    },
    deadline_from: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["deadline_from"] != null) {
            return this.newBill.deadline_from;
          }
        }
        return null;
      },
      set: function (deadline_from) {
        this.addDeadlineFromForBillAction(deadline_from);
      },
    },
    deadline_to: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["deadline_to"] != null) {
            return this.newBill.deadline_to;
          }
        }
        return null;
      },
      set: function (deadline_to) {
        this.addDeadlineToForBillAction(deadline_to);
      },
    },
    selecteNewDepartments: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["departments"] != null) {
            // console.log(this.newBill.departments, "this.newBill.departments");

            return this.newBill.departments;
          }
        }
        return null;
      },
      set: function (departments) {
        // alert("asd");
        //let depIds = departments.map(a => a.id);
        // console.log(departments, "departments");
        if (departments != null) {
          this.addDepartmentsForBillAction(departments);
        }
      },
    },
    selecteNewSuppliers: {
      get: function () {
        if (this.newBill != null) {
          if (this.newBill["suppliers"] != null) {
            return this.newBill.suppliers;
          }
        }
        return null;
      },
      set: function (suppliers) {
        // alert("asd");
        //let depIds = suppliers.map(a => a.id);

        if (suppliers != null) {
          this.addSuppliersForBillAction(suppliers);
        }
      },
    },
    // selectResponsiblePerson: {
    //   get: function () {
    //     if (this.newBill != null) {
    //       if (this.newBill["responsible_person"] != null) {
    //         return this.newBill.responsible_person;
    //       }
    //     }
    //     return null;
    //   },
    //   set: function (responsible_person) {
    //     this.addResponsiblePersonForBillAction(responsible_person);
    //   },
    // },
    // selectTotalPrice: {
    //   get: function () {
    //     if (this.newBill != null) {
    //       if (
    //         this.newBill["total_price"] != null ||
    //         this.newBill["total_price"] != undefined
    //       ) {
    //         return this.newBill.total_price;
    //       }
    //     }
    //   },
    //   set: function (value) {
    //     this.addTotalPriceForBillAction(value);
    //   },
    // },
    // selectUnitPrice: {
    //   get: function () {
    //     if (this.newBill != null) {
    //       if (this.newBill["unit_price"] != null) {
    //         return this.newBill.unit_price;
    //       }
    //     }
    //   },
    //   set: function (value) {
    //     this.addUnitPriceForBillAction(value);
    //   },
    // },
    // selectPaymentDate: {
    //   get: function () {
    //     if (this.newBill != null) {
    //       if (this.newBill["payment_date"] != null) {
    //         return this.newBill.payment_date;
    //       }
    //     }
    //     return null;
    //   },
    //   set: function (payment_date) {
    //     this.addPaymentDateForBillAction(payment_date);
    //   },
    // },
    editStatus: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["status"] != null) {
            if (this.bill.status.id !== undefined) {
              return this.bill.status;
            } else {
              return this.statusList.filter((s) => s.id === this.bill.status);
            }
          }
        }
        return null;
      },
      set: function (status) {
        this.editStatusForBillAction(status);
      },
    },
    editStep: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["step"] != null) {
            if (this.bill.step.id !== undefined) {
              return this.bill.step;
            } else {
              return {
                name: `Step ${this.bill.step}`,
                id: this.bill.step,
              };
            }
          }
        }
        return null;
      },
      set: function (step) {
        this.editStepForBillAction(step);
      },
    },
    editBillType: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["bill_type"] != null) {
            return this.bill.bill_type;
          }
        }
        return null;
      },
      set: function (bill_type) {
        this.editBillTypeForBillAction(bill_type);
      },
    },
    editDeadline_from: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["deadline_from"] != null) {
            return this.bill.deadline_from;
          }
        }
        return null;
      },
      set: function (deadline_from) {
        this.editDeadlineFromForBillAction(deadline_from);
      },
    },
    editDeadline_to: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["deadline_to"] != null) {
            return this.bill.deadline_to;
          }
        }
        return null;
      },
      set: function (deadline_to) {
        this.editDeadlineToForBillAction(deadline_to);
      },
    },
    editCompany: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["company"] != null) {
            return this.bill.company;
          } else {
            if (this.bill.responsible_person["department"] != null) {
              if (this.bill.responsible_person.department["company"] != null) {
                return this.bill.responsible_person.department.company;
              }
            }
          }
        }
        return null;
      },
      set: function (company) {
        if (company != null) {
          this.editCompanyForBillAction(company);
          this.getDepartmentByCompanyId(company.id);
        }
        this.setDepartmentForCompanyEditNullAction();
      },
    },
    editDepartment: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["assigned_dep_id"] != null) {
            return this.bill.assigned_dep_id.name;
          }
        }
        return null;
      },
      set: function (department) {
        this.editDepartmentForBillAction(department);
        this.getResponsiblePersonsByDepartmentId(department.id);
        this.editResponsiblePersonForBillAction(null);
      },
    },
    editResponsiblePerson: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["responsible_person"] != null) {
            return this.bill.responsible_person;
          }
        }
        return null;
      },
      set: function (responsible_person) {
        this.editResponsiblePersonForBillAction(responsible_person);
      },
    },
    editTotalPrice: {
      get: function () {
        if (this.bill != null) {
          if (
            this.bill["total_price"] != null ||
            this.bill["total_price"] != undefined
          ) {
            return this.bill.total_price;
          }
        }
      },
      set: function (value) {
        this.editTotalPriceForBillAction(value);
      },
    },
    editUnitPrice: {
      get: function () {
        if (this.bill != null) {
          if (
            this.bill["unit_price"] != null ||
            this.bill["unit_price"] != undefined
          ) {
            return this.bill.unit_price;
          }
        }
      },
      set: function (value) {
        this.editUnitPriceForBillAction(value);
      },
    },
    editPaymentDate: {
      get: function () {
        if (this.bill != null) {
          if (this.bill["payment_date"] != null) {
            return this.bill.payment_date;
          }
        }
        return null;
      },
      set: function (payment_date) {
        this.editPaymentDateForBillAction(payment_date);
      },
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    rolePermissions() {
      return this.$store.getters["auth/rolePermissions"];
    },
    directPermissions() {
      return this.$store.getters["auth/directPermissions"];
    },
    files() {
      return this.bill.files.filter((f) => f.deleted_at === null);
    },
    filesDeleted() {
      return this.bill.files.filter((f) => f.deleted_at !== null);
    },
    filesToDeleted() {
      return this.bill.files_deleted;
    },
    searchCompany: {
      get: function () {
        return this.query.search.company;
      },
      set: function (value) {
        this.query.search.company = value;
        if (value != null) {
          this.getDepartmentByCompanyId(value.id);
        }
        this.query.search.department = null;
        this.query.search.procurement_officer = null;
        this.query.search.responsible_person = null;
      },
    },
    searchDepartment: {
      get: function () {
        return this.query.search.department;
      },
      set: function (value) {
        this.query.search.department = value;
        if (value != null) {
          this.getProcurementOfficerByDepartmentId(value.id);
          this.getResponsiblePersonsByDepartmentId(value.id);
        }
        this.query.search.procurement_officer = null;
        this.query.search.responsible_person = null;
      },
    },
    searchProcurementOfficer: {
      get: function () {
        return this.query.search.procurement_officer;
      },
      set: function (value) {
        this.query.search.procurement_officer = value;
      },
    },
    searchResponsiblePerson: {
      get: function () {
        return this.query.search.responsible_person;
      },
      set: function (value) {
        this.query.search.responsible_person = value;
      },
    },
    searchSupplier: {
      get: function () {
        return this.query.search.suppliers;
      },
      set: function (value) {
        this.query.search.suppliers = value;
      },
    },
    searchBillType: {
      get: function () {
        return this.query.search.bill_type;
      },
      set: function (value) {
        this.query.search.bill_type = value;
      },
    },
  },
  methods: {
    ...mapActions("bills", {
      fetchAllBills: FETCH_ALL_BILLS_ACTION,
      fetchDetailBill: FETCH_DETAIL_BILL_ACTION,
      storeBill: STORE_BILL_ACTION,
      requestBill: "requestBillAction",
      updateBill: UPDATE_BILL_ACTION,
      addBillInput: ADD_BILL_INPUT_ACTION,
      updateBillInput: UPDATE_BILL_INPUT_ACTION,
      deleteBill: DELETE_BILL_ACTION,
      RemoveFile: REMOVE_FILE_ACTION,
      setErrors: SET_ERRORS_ACTION,

      addStepForBillAction: "addStepForBillAction",
      addBillTypeForBillAction: "addBillTypeForBillAction",
      addCompanyForBillAction: "addCompanyForBillAction",
      addDeadlineFromForBillAction: "addDeadlineFromForBillAction",
      addDeadlineToForBillAction: "addDeadlineToForBillAction",
      addResponsiblePersonForBillAction: "addResponsiblePersonForBillAction",
      addTotalPriceForBillAction: "addTotalPriceForBillAction",
      addUnitPriceForBillAction: "addUnitPriceForBillAction",
      addPaymentDateForBillAction: "addPaymentDateForBillAction",
      addDepartmentsForBillAction: "addDepartmentsForBillAction",
      addSuppliersForBillAction: "addSuppliersForBillAction",

      editStatusForBillAction: "editStatusForBillAction",
      editStepForBillAction: "editStepForBillAction",
      editBillTypeForBillAction: "editBillTypeForBillAction",
      editCompanyForBillAction: "editCompanyForBillAction",
      editDeadlineFromForBillAction: "editDeadlineFromForBillAction",
      editDeadlineToForBillAction: "editDeadlineToForBillAction",
      editResponsiblePersonForBillAction: "editResponsiblePersonForBillAction",
      editTotalPriceForBillAction: "editTotalPriceForBillAction",
      editUnitPriceForBillAction: "editUnitPriceForBillAction",
      editPaymentDateForBillAction: "editPaymentDateForBillAction",
      editDepartmentForBillAction: "editDepartmentForBillAction",
      editSupplierForBillAction: "editSupplierForBillAction",

      getBillAttachments: "getBillAttachments",

      updateBillFilesToDelete: "updateBillFilesToDelete",
      updateBillFilesToRestore: "updateBillFilesToRestore",
      updateBillFilesFromRestoreToDelete: "updateBillFilesFromRestoreToDelete",
      updateBillFilesToRestoreFromDeleted:
        "updateBillFilesToRestoreFromDeleted",
      updateBillFilesToDeletePermanent: "updateBillFilesToDeletePermanent",

      approveBill: "approveBill",
      resetBillApprove: "resetBillApprove",
      cancelBill: "cancelBill",
      resetBillCancel: "resetBillCancel",

      setDepartmentForCompanyNullAction: "setDepartmentForCompanyNullAction",
      setDepartmentForCompanyEditNullAction:
        "setDepartmentForCompanyEditNullAction",
      exportBillsToExcel: "exportBillsToExcel",
    }),

    exportOnExcel() {
      const tableData = [];

      this.billsPaginatedData.data.forEach((item, index) => {
        tableData.push([
          this.query.page * 10 - 10 + (index + 1),
          item.type,
          item.value,
          item.bill_no,
          item.assigned_dep_id,
          item.updated_by,
          // `${item.updated_by.first_name} ${item.updated_by.last_name}`,
          item.supplier,
          `${item.created_by.first_name} ${item.created_by.last_name}`,
          new Date(item.created_at).toLocaleDateString(),
          `Step ${item.step}`,
          this.checkBillStatus(item.status),
        ]);
      });

      const ws = XLSX.utils.aoa_to_sheet([
        [
          "#",
          "LLoji",
          "Vlera",
          "Nr i faturës",
          "Departamenti",
          "Pranoi nga dep",
          "Furnitori",
          "Krijuar nga",
          "Data",
          "Step",
          "Status",
        ],
        ...tableData,
      ]);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Bills");

      const fileName = "Fatura.xlsx";

      XLSX.writeFile(wb, fileName);
    },
    ...mapActions("users", {
      fetchAllUsers: FETCH_ALL_USERS_ACTION,
    }),
    ...mapActions("departments", {
      fetchAllDepartments: FETCH_ALL_DEPARTMENTS_ACTION,
      getDepartmentByCompanyId: "getDepartmentByCompanyId",
    }),
    ...mapActions("suppliers", {
      fetchAllSuppliers: FETCH_ALL_SUPPLIERS_ACTION,
      // getSupplierByCompanyId: "getSupplierByCompanyId",
    }),
    ...mapActions("responsible_person", {
      getResponsiblePersonsByDepartmentId:
        "getResponsiblePersonsByDepartmentId",
    }),
    ...mapActions("procurement_officer", {
      getProcurementOfficerByDepartmentId:
        "getProcurementOfficerByDepartmentId",
    }),
    handleModalClick(event) {
      // Check if the clicked element should close the modal
      if (!event.target.classList.contains("specific-close-element")) {
        event.stopPropagation();
      } else {
        this.closeModal();
      }
    },
    handleOutsideClick(event) {
      const clickedElementId = event.target.id;
      const modalIds = ["modal-edit", "modal-info", "modal-another"]; // Add the IDs of your modals
      if (!modalIds.includes(clickedElementId)) {
        event.stopPropagation();
      }
    },
    closeModal() {
      this.$store.dispatch("ui/setModalActive", false);
      this.modalAddActive = false;
      this.modalRequestActive = false;
      this.modalInfoActive = false;
      this.modalEditActive = false;
      this.modalAtthacmentsActive = false;
      this.labelFilesNameInsert = "Choose a file";
      this.labelFilesName = "Choose a file";
      this.labelFilesNameRequest = "Choose a file";
      this.$store.commit("bills/setNewBillInputNull");
    },
    showAddModal() {
      this.fetchAllDepartments();
      this.fetchAllSuppliers();
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.setErrors(null);
      // this.fetchAllBillTypes();
      // this.fetchAllCompanies();
      return (this.modalAddActive = !this.modalAddActive);
    },
    showRequestModal() {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.setErrors(null);
      this.fetchAllCompanies();
      this.fetchAllSuppliers();
      if (this.user.roles !== undefined) {
        if (this.user.roles[0].name == "Procurement Officer") {
          this.getDepartmentByCompanyId(this.user.department.company.id);
          this.getResponsiblePersonsByDepartmentId(this.user.department_id);
        } else if (this.user.roles[0].name == "Director Department") {
          this.getDepartmentByCompanyId(this.user.department.company.id);
          this.getResponsiblePersonsByDepartmentId(this.user.department_id);
        } else {
          this.fetchAllDepartments();
        }
      } else {
        this.fetchAllDepartments();
      }
      return (this.modalRequestActive = true);
    },
    showInfoModal(id) {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.fetchDetailBill(id);
      return (this.modalInfoActive = !this.modalInfoActive);
    },
    showAttachmentsModal(id) {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      this.fetchDetailBill(id);
      this.setErrors(null);
      return (this.modalAtthacmentsActive = !this.modalAtthacmentsActive);
    },
    async showEditModal(id) {
      this.$store.dispatch("ui/setModalActive", !this.isModalActive);
      await this.fetchDetailBill(id);
      // this.fetchAllBillTypes();
      // this.fetchAllCompanies();
      // if (this.bill != null) {
      //   this.getDepartmentByCompanyId(
      //     this.bill.created_by.department.company.id
      //   );
      //   this.getResponsiblePersonsByDepartmentId(
      //     this.bill.created_by.department.id
      //   );
      // }
      this.setErrors(null);
      return (this.modalEditActive = !this.modalEditActive);
    },
    addBillInputAction(e) {
      this.addBillInput(e);
    },
    updateBillInputAction(e) {
      this.updateBillInput(e);
    },

    onSubmitAdd() {
      const {
        // name,
        selectedType = document.querySelector('input[name="type"]:checked')
          .value,
        // ? null
        // : document.querySelector('input[name="type"]:checked').value,
        value,
        bill_no,
        suppliers,
        description,
        comment,
        departments,
      } = this.newBill;

      let fd = new FormData();
      fd.append("status", "1");
      fd.append("step", "1");
      // if (name != null) {
      //   fd.append("name", name);
      // }

      fd.append("type", selectedType);

      if (value != null) {
        fd.append("value", value);
      }
      if (bill_no != null) {
        fd.append("bill_no", bill_no);
      }
      if (suppliers != null) {
        fd.append("supplier", suppliers.id);
      }
      if (description != null) {
        fd.append("description", description);
      }
      if (comment != null) {
        fd.append("comment", comment);
      }
      if (departments.id != null) {
        fd.append("departments", departments.id);
      }

      if (this.$refs.filesInsert.files.length >= 1) {
        Array.from(this.$refs.filesInsert.files).forEach((f) => {
          fd.append("files[]", f);
        });
      }
      if (this.selectNotifyDay != null) {
        fd.append("notify_me", this.selectNotifyDay);
      }
      let user = JSON.parse(localStorage.getItem("user"));
      const vm = this; // Store a reference to the Vue component
      const xhttp = new XMLHttpRequest();
      xhttp.onload = function () {
        var response = this.responseText;
        if (response == "1") {
          const response = confirm(
            "Fatura me numër të njejtë ekziston, a dëshironi të vazhdoni?"
          );
          if (response) {
            vm.storeBill(fd);
          }
        } else {
          vm.storeBill(fd);
        }
      };
      xhttp.open(
        "GET",
        "https://pmkbackend.artmotion.net/api/admin/get_exist_bills/" +
          bill_no +
          "/" +
          suppliers.id,
        true
      );
      xhttp.setRequestHeader("Authorization", "Bearer " + user.token);
      xhttp.send();
    },
    onSubmitRequest() {
      const {
        step,
        name,
        bill_type,
        name_of_billor,
        address,
        purpose_billor,
        company,
        deadline_from,
        deadline_to,
        responsible_person,
        total_price,
        unit_price,
        payment_date,
        payment_terms,
        billor_obligations,
        company_obligations,
        department,
        comment,
      } = this.newBill;

      let fd = new FormData();
      fd.append("status", "2");
      if (step != null) {
        fd.append("step", "2");
      }
      if (step === undefined) {
        fd.append("step", "2");
      }
      if (name != null) {
        fd.append("name", name);
      }
      if (bill_type != null) {
        fd.append("bill_type_id", bill_type.id);
      }
      if (name_of_billor != null) {
        fd.append("name_of_billor", name_of_billor);
      }
      if (address != null) {
        fd.append("address", address);
      }
      if (purpose_billor != null) {
        fd.append("purpose_billor", purpose_billor);
      }
      if (company != null) {
        fd.append("company_id", company.id);
      }
      if (deadline_from != null) {
        fd.append("deadline_from", deadline_from);
      }
      if (deadline_to != null) {
        fd.append("deadline_to", deadline_to);
      }
      if (department === undefined && this.user.roles !== undefined) {
        if (this.user.roles[0].name == "Procurement Officer") {
          fd.append("department_id", this.user.department_id);
        } else if (this.user.roles[0].name == "Director Department") {
          fd.append("department_id", this.user.department_id);
        }
      } else {
        fd.append("department_id", department.id);
      }
      if (responsible_person != null) {
        fd.append("responsible_person", responsible_person.id);
      }
      if (total_price != null) {
        fd.append("total_price", total_price);
      }
      if (unit_price != null) {
        fd.append("unit_price", unit_price);
      }
      if (payment_date != null) {
        fd.append("payment_date", payment_date);
      }
      if (payment_terms != null) {
        fd.append("payment_terms", payment_terms);
      }
      if (billor_obligations != null) {
        fd.append("billor_obligations", billor_obligations);
      }
      if (company_obligations != null) {
        fd.append("company_obligations", company_obligations);
      }
      if (department != null) {
        fd.append("department_id", department.id);
      }
      if (comment != null) {
        fd.append("comment", comment);
      }
      if (this.$refs.filesRequest.files.length >= 1) {
        Array.from(this.$refs.filesRequest.files).forEach((f) => {
          fd.append("files[]", f);
        });
      }

      this.requestBill(fd);
    },
    onSubmitEdit() {
      const {
        id,
        nameNew,
        // type,
        valueNew,
        bill_noNew,
        supplierNew,
        status,
        step,
        commentNew,
      } = this.bill;

      let fd = new FormData();
      fd.append("_method", "PUT");
      if (status != null) {
        if (status.id !== undefined) {
          fd.append("status", status.id);
        } else {
          fd.append("status", status);
        }
      }
      if (step !== null) {
        if (step.id !== undefined) {
          fd.append("step", step.id);
        } else {
          fd.append("step", step);
        }
      }
      if (id != null) {
        fd.append("id", id);
      }
      if (nameNew != null) {
        fd.append("name", nameNew);
      }
      // if (type != null) {
      //   fd.append("type_id", type.id);
      // }
      if (valueNew != null) {
        fd.append("value", valueNew);
      }
      if (bill_noNew != null) {
        fd.append("bill_no", bill_noNew);
      }
      if (supplierNew != null) {
        fd.append("supplier", supplierNew);
      }

      if (commentNew != null) {
        fd.append("comment", commentNew);
      }
      if (this.$refs.filesInsert.files.length >= 1) {
        Array.from(this.$refs.filesInsert.files).forEach((f) => {
          fd.append("files[]", f);
        });
      }

      this.updateBill(fd);
    },

    onSubmitSaveAttachments() {
      const { id, files_deleted_permanent } = this.bill;

      let fd = new FormData();
      fd.append("_method", "PUT");
      if (id != null) {
        fd.append("id", id);
      }
      Array.from(this.$refs.filesEdit.files).forEach((f) => {
        fd.append("files[]", f);
      });
      // if (files_deleted !== undefined) {
      //   fd.append("files_deleted", JSON.stringify(files_deleted));
      // }
      // if (files_restore !== undefined) {
      //   fd.append("files_restore", JSON.stringify(files_restore));
      // }
      if (files_deleted_permanent !== undefined) {
        fd.append(
          "files_deleted_permanent",
          JSON.stringify(files_deleted_permanent)
        );
      }

      this.updateBill(fd);
    },

    // showArchivedBills() {
    //   let tempPayload = {
    //     ...this.query,
    //     status: [{
    //       id: 3,
    //       name: "Archive",
    //     }],
    //   };

    //   this.fetchAllBills({ ...tempPayload, filterArchivedBills: true });
    //   // this.fetchAllBills({ ...this.query, filterArchivedBills: true });
    // },
    showInProgressBills() {
      this.fetchAllBills({ ...this.query, filterProgressBills: true });
    },
    showArchivedBills() {
      this.fetchAllBills({ ...this.query, filterArchivedBills: true });
    },

    showAllBillsss() {
      this.fetchAllBills(this.query);
    },
    async onDeleteFile(file) {
      const { isConfirmed } = await this.$swal.fire({
        input: "text",
        inputLabel: `Cancel file: ${file.file_name}`,
        cancelButtonText: "Cancel",
        confirmButtonText: "Remove file",
        confirmButtonColor: "#dc3545",
        showCancelButton: true,
      });
      if (isConfirmed) {
        this.RemoveFile({ id: file.id }).then(() => {
          this.fetchDetailBill(this.bill.id);
        });
      }
    },

    async onApprove(id, step) {
      const { value: modalValues, isConfirmed: isConfirmed } =
        await this.$swal.fire({
          cancelButtonText: "Cancel",
          confirmButtonText: "Approve",
          html: `
                    <label>Approve</label>
                    <textarea aria-label="Add a comment" class="swal2-textarea" placeholder="Add a comment..." id="swal2-input" style="display: flex;"></textarea>
                    <input style="${
                      step == 6 ? "" : "display:none;"
                    }" type="file" id="approvalFile">
                    <p style="${
                      this.showRequiredMessageForComment
                        ? "color: salmon;"
                        : "color: salmon;display:none"
                    }">Comment is required</p>
                    <p style="${
                      this.showRequiredMessageForFile
                        ? "color: salmon;"
                        : "color: salmon;display:none"
                    }">File is required</p>
                `,
          preConfirm: () => [
            document.getElementById("swal2-input").value,
            document.getElementById("approvalFile").value,
          ],
          showCancelButton: true,
        });
      if (isConfirmed) {
        if (modalValues[0]) {
          if (step == 6 && !modalValues[1]) {
            this.showRequiredMessageForFile = true;
          }
          this.approveBill({
            id: id,
            comment: modalValues[0],
            file: modalValues[1],
          });
        } else {
          if (step == 6 && !modalValues[1]) {
            this.showRequiredMessageForFile = true;
          }
          this.showRequiredMessageForComment = true;
          this.onApprove(id, step);
        }
      }
    },
    async onCancel(id) {
      const { value: comment, isConfirmed: isConfirmed } =
        await this.$swal.fire({
          input: "textarea",
          inputLabel: "Cancel",
          cancelButtonText: "Cancel",
          confirmButtonText: "Cancel bill",
          confirmButtonColor: "#dc3545",
          inputPlaceholder: "Add a comment...",
          inputAttributes: {
            "aria-label": "Add a comment",
          },
          showCancelButton: true,
        });
      if (isConfirmed) {
        if (comment) {
          this.cancelBill({ id: id, comment: comment });
        } else {
          this.cancelBill({ id: id });
        }
      }
    },

    onFileInsert() {
      let files = this.$refs.filesInsert.files;
      this.changeFileLabels(files, "labelFilesNameInsert");
    },

    onFileRequest() {
      let files = this.$refs.filesRequest.files;
      this.changeFileLabels(files, "labelFilesNameRequest");
    },
    onFileChange() {
      let files = this.$refs.filesEdit.files;
      this.changeFileLabels(files, "labelFilesName");
    },
    changeFileLabels(files, label) {
      this.label = null;
      Array.from(files).forEach((f) => {
        if (this.label === null) {
          this.label = f.name;
        } else {
          this.label = this.label + ", " + f.name;
        }
      });
      this[label] = this.label;
    },
    showDeleteModal(id) {
      this.$swal
        .fire({
          text: "Are you sure to delete the bill ?",
          icon: "error",
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes",
          showCancelButton: true,
        })
        .then((result) => {
          if (result["isConfirmed"]) {
            this.deleteBill(id);
            // this.fetchAllTasks();
            this.fetchAllBills(this.query);
            this.$swal.fire({
              text: "Bill has been deleted.",
              icon: "success",
              timer: 10000,
            });
          }
        });
    },
    getResults() {
      this.fetchAllBills(this.query);
    },
    getRouteQuery() {
      if (this.$route.query.page != null) {
        this.query.page = parseInt(this.$route.query.page);
      }
      return this.query;
    },
    getStatusId() {
      if (this.$route.query.status != null) {
        this.query.search.status.id = this.$route.query.status;
      }

      return this.query;
    },
    modalContent() {},
    checkIfFieldHasErrors(errors, field, loader) {
      if (errors != null && !loader) {
        if (errors[field] != null) {
          return true;
        }
      }
      return false;
    },
    checkBillStatus(status) {
      if (status == 1) {
        return "REQUESTED";
      } else if (status == 2) {
        return "PENDING";
      } else if (status == 3) {
        return "APPROVED FROM CEO";
      } else if (status == 4) {
        return "CANCELED";
      } else if (status == 5) {
        return "APPROVED FROM ADMIN";
      } else if (status == 6) {
        return "Printed & Closed";
      }
    },
    getStatusStyle(status) {
      switch (status) {
        case 1:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "#17a2b8",
            padding: "2px 5px",
            "font-size": "15px",
          };
        case 2:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "#f7cd80",
            padding: "2px 5px",
            "font-size": "15px",
          };
        case 3:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "green",
            padding: "2px 5px",
            "font-size": "15px",
          };
        case 4:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "red",
            padding: "2px 5px",
            "font-size": "15px",
          };
        case 5:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "#20c997",
            padding: "2px 5px",
            "font-size": "15px",
          };
        case 6:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "#28a745",
            padding: "2px 5px",
            "font-size": "15px",
          };
        default:
          return {
            "border-radius": "5px",
            color: "#fff",
            "font-weight": "700",
            background: "grey",
            padding: "2px 5px",
            "font-size": "15px",
          };
      }
    },

    firstAndLastname({ first_name, last_name }) {
      if (first_name !== null && last_name !== null) {
        return `${first_name} ${last_name}`;
      } else {
        // alert("no firstname");
        return "";
      }
    },
    getBillAttachment(file) {
      return this.getBillAttachments(file);
    },
    showAttachmentIcon(file_extension) {
      if (file_extension == "docx") {
        return "fa-file-word";
      } else if (file_extension == "doc") {
        return "fa-file-word";
      } else if (file_extension == "pdf") {
        return "fa-file-pdf";
      } else if (file_extension == "xlsx") {
        return "fa-file-excel";
      } else if (file_extension == "xls") {
        return "fa-file-excel";
      } else {
        return "";
      }
    },
    checkIfUserHasPermissionToShowAll() {
      let permission = "Bill Show All";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canShowAll = true);
      }
      if (p2.length >= 1) {
        return (this.canShowAll = true);
      }

      return (this.canShowAll = false);
    },
    checkIfUserHasPermissionToShow() {
      let permission = "Bill Show";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canShow = true);
      }
      if (p2.length >= 1) {
        return (this.canShow = true);
      }

      return (this.canShow = false);
    },
    checkIfUserHasPermissionToAdd() {
      let permission = "Bill Add";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canAdd = true);
      }
      if (p2.length >= 1) {
        return (this.canAdd = true);
      }

      return (this.canAdd = false);
    },
    checkIfUserHasPermissionToRequest() {
      let permission = "Bill Request";
      let currentRolePermissions = this.rolePermissions.filter(
        (p) => p.name === permission
      );
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (currentRolePermissions.length >= 1) {
        return (this.canRequest = true);
      }
      if (p2.length >= 1) {
        return (this.canRequest = true);
      }

      return (this.canRequest = false);
    },
    checkIfUserHasPermissionToEdit() {
      let permission = BILL_PERMISSIONS.BILL_EDIT;
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canEdit = true);
      }
      if (p2.length >= 1) {
        return (this.canEdit = true);
      }

      return (this.canEdit = false);
    },
    checkIfUserHasPermissionToDelete() {
      let permission = "Bill Delete";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canDelete = true);
      }
      if (p2.length >= 1) {
        return (this.canDelete = true);
      }

      return (this.canDelete = false);
    },
    checkIfUserHasPermissionToApprove() {
      let permission = "Bill Approve";

      // let permission = BILL_PERMISSIONS.BILL_APPROVE;
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);

      if (p1.length >= 1) {
        return (this.canApprove = true);
      }
      if (p2.length >= 1) {
        return (this.canApprove = true);
      }

      return (this.canApprove = false);
    },
    checkIfUserHasPermissionToPrint() {
      if( this.user.roles[0].name == "Director Department" &&
      this.user.department.name == "Prokurimi"){
        return (this.canPrintAll = true);
      }

      return (this.canPrintAll = false);
    },
    checkIfUserHasPermissionToBillAttachments() {
      let permission = "Bill Attachments";
      let p1 = this.rolePermissions.filter((p) => p.name === permission);
      let p2 = this.directPermissions.filter((p) => p.name === permission);
      if (p1.length >= 1) {
        return (this.canBillAttachments = true);
      }
      if (p2.length >= 1) {
        return (this.canBillAttachments = true);
      }

      return (this.canBillAttachments = false);
    },

    clear() {
      this.$refs.filesInsert.value = "";
      this.labelFilesNameInsert = "Choose a file";
    },
    // canPrintAll(){
    //   if( this.user.roles[0].name == "Director Department" &&
    //   this.user.department.name == "Prokurimi"){
    //     return true;
    //   }
    //   return false;
    // },
    canApproveUser(status, assigned_dep_id) {
      if (
        this.user.roles[0].name == "Super Admin" &&
        (status == 1 || status == 2)
      ) {
        return true;
      }
      // if (this.user.roles[0].name == "Director Department" && status == 1 && this.user.department.name != "Prokurimi") {
      //   return true;
      // }
      if (
        this.user.roles[0].name == "Director Department" &&
        this.user.department.name == "Prokurimi" &&
        status == 1
      ) {
        return true;
      }
      if (
        this.user.roles[0].name == "Executive Director" &&
        (status == 1 || status == 2)
      ) {
        return true;
      }

      if (
        status == 1 &&
        (this.user.department.name == "Sigurimi" ||
          this.user.department.name == "Transport" ||
          this.user.department.name == "Teknika" ||
          this.user.department.name == "Marketingu" ||
          this.user.department.name == "Shitja" ||
          this.user.department.name == "Transport" ||
          this.user.department.name == "Programacioni" ||
          this.user.department.name == "Financa" ||
          this.user.department.name == "Zyra Ligjore") &&
        this.user.department.name == assigned_dep_id
      ) {
        return true;
      }
      // if (
      //   this.user.department.name == item.assigned_dep_id &&
      //   item.status == 1
      // ) {
      //   return true;
      // }
      return false;
    },

    PDF() {
      const bills = this.billsPaginatedData.data;

      // Open a new window for the PDF
      const WinPrint = window.open();

      // Iterate through each bill and generate the content for the PDF
      for (const [index, bill] of bills.entries()) {
        const printContents = this.generatePrintContent(bill, index);

        // Execute the code for each bill
        const { id, status, step } = bill;
        let fd = new FormData();
        fd.append("_method", "PUT");
        if (status != null) {
          if (status.id !== undefined) {
            fd.append("status", 6);
          } else {
            fd.append("status", 6);
          }
        }
        if (step !== null) {
          if (step.id !== undefined) {
            fd.append("step", step.id);
          } else {
            fd.append("step", step);
          }
        }
        if (id != null) {
          fd.append("id", id);
        }
        this.updateBill(fd);

        // Write the content for the current bill
        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Bill PDF</title>
            </head>
            <body>
              ${printContents}
            </body>
          </html>`);

        // Add a page break between bills, except for the last one
        if (index < bills.length - 1) {
          WinPrint.document.write(
            '<div style="page-break-before: always;"></div>'
          );
        }
      }

      // Close the document and print
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    generatePrintContent(bill, index) {
      console.log("Bill object:", bill);
      const commentsHtml = bill.comments
        ? `<tr style="height: auto; border: 1px solid black;">
          <td>Komentet:</td>
          <td v-if="bill.comments.length >= 1" style="max-width: 400px">
            ${bill.comments
              .map(
                (comment) => `<div class="mb-1">
                  <span v-if="${
                    comment.steps !== null
                  }" class="badge badge-warning">Step ${comment.steps}</span>
                  <p class="mb-0">${comment.name}</p>
                  -<small>${comment.user_id}</small>
                </div>`
              )
              .join("")}
          </td>
        </tr>`
        : "";

      const documentsHtml = bill.files
        ? `
        <tr style="border: 1px solid black;">
          <td>Dokumentet e bashkangjitura:</td>
          <td>
            ${bill.files
              .map(
                (file) => `<div key="${file.id}">
                  <a href="#" @click="getBillAttachment(${JSON.stringify(
                    file
                  )})" class="btn-link text-secondary">
                    <i class="far fa-fw ${this.showAttachmentIcon(
                      file.file_extension
                    )}"></i>${file.file_name}
                  </a>
                </div>`
              )
              .join("")}
          </td>
        </tr>`
        : "";

      const prtHtml = `
      <div id="printTable${index}">
      <table
        v-if="!isLoading && billsPaginatedData"
        id="printTable"
        class="table table-hover table-borderless table-striped p-0"
      >
        <div class="" style="width: 100%;">
        <img style="width: 50%;" src="../../../public/img/Picture3.svg" >
        </div>
        <h3 style="text-align: center;"> FORMULARI I APROVIMIT TË FATURAVE </h3>
        <thead>
          <tr>
            <th>Emërtimi</th>
            <th>Përshkrimi</th>
          </tr>
        </thead>

        <tbody>
          <tr style="border: 1px solid black;">
            <td style="width: 45%">Id:</td>
            <td style="width: 50%">${bill.id}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Furnitori:</td>
            <td>${bill.supplier}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Numri i faturës:</td>
            <td>${bill.bill_no}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Lloji:</td>
            <td>${bill.type}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Vlera:</td>
            <td>${bill.value}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Përshrkimi:</td>
            <td>${bill.description || ""}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Për Departamentin:</td>
            <td>${bill.assigned_dep_id}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Pranoi nga departamenti</td>
            <td>${
              bill.updated_by && bill.updated_by.first_name
                ? bill.updated_by.first_name
                : ""
            } ${
        bill.updated_by && bill.updated_by.last_name
          ? bill.updated_by.last_name
          : ""
      }</td>
            </tr>
          <tr style="border: 1px solid black;">
          <td>Komenti:</td>
          <td>${bill.name || ""}</td>
        </tr>
          <tr style="border: 1px solid black;">
            <td>Krijuar nga:</td>
            <td>${bill.created_by.first_name} ${bill.created_by.last_name}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Step:</td>
            <td v-if="bill.step">
              <span class="badge badge-warning">Step ${bill.step}</span>
            </td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Status:</td>
            <td>
              <span class="badge badge-info">${this.checkBillStatus(
                bill.status
              )}</span>
            </td>
          </tr>
          ${commentsHtml}
          ${documentsHtml}
          <tr style="border: 1px solid black;">
            <td>Së fundmi modifikuar më:</td>
            <td>${new Date(bill.updated_at).toLocaleString()}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Krijuar më:</td>
            <td>${new Date(bill.created_at).toLocaleString()}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Aprovuar herën e parë më:</td>
            <td>${new Date(bill.approved_first).toLocaleString() || ""}</td>
          </tr>
          <tr style="border: 1px solid black;">
            <td>Aprovuar herën e dytë më:</td>
            <td>${new Date(bill.approved_second).toLocaleString()|| ""}</td>
          </tr>
        </tbody>
      </table>
    </div>`;

      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      return `
        ${stylesHtml}
        ${prtHtml}
      `;
    },

    printData() {
      const { id, status, step } = this.bill;

      let fd = new FormData();
      fd.append("_method", "PUT");
      if (status != null) {
        if (status.id !== undefined) {
          fd.append("status", 6);
        } else {
          fd.append("status", 6);
        }
      }
      if (step !== null) {
        if (step.id !== undefined) {
          fd.append("step", step.id);
        } else {
          fd.append("step", step);
        }
      }
      if (id != null) {
        fd.append("id", id);
      }

      this.updateBill(fd);

      const prtHtml = document.getElementById("printTable").outerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open();

      WinPrint.document.write(`<!DOCTYPE html>
            <html>
            
              <head>
              <title>Bill PDF</title>
                ${stylesHtml}
              </head>
              <body>
                ${prtHtml}
              </body>
            </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    showSearch() {
      this.search = !this.search;
      this.fetchAllBills();
      this.fetchAllSuppliers();
    },

    async onSubmitSearch() {
      this.query.page = 1;
      // this.query.status = 7;
      await this.fetchAllBills(this.query);
    },

    // giveAccessTo(accessRoles) {
    //   return canGivenRolesHaveAccess(accessRoles, this.user.roles);
    // },
  },
  watch: {
    updatedData: function () {
      if (this.updatedData !== null && !this.isUpdating) {
        this.$store.dispatch("ui/setModalActive", false);
        this.$swal.fire({
          text: "Bill has been updated successfully!",
          icon: "success",
          timer: 10000,
        });

        this.fetchAllBills(this.query);
        this.labelFilesName = "Choose a file";
        this.modalEditActive = false;
        return (this.modalAtthacmentsActive = false);
      }
    },
    createdData: function () {
      if (this.createdData !== null && !this.isCreating) {
        this.$store.dispatch("ui/setModalActive", false);
        this.$swal.fire({
          text: "Bill has been added successfully!",
          icon: "success",
          timer: 10000,
        });

        this.fetchAllBills(this.query);
        this.modalAddActive = false;
        return (this.modalRequestActive = false);
      }
    },
    billApprove: function () {
      if (this.billApprove == "success") {
        this.$swal.fire({
          text: "Request has been approved!",
          icon: "success",
          timer: 10000,
        });
        this.fetchAllBills(this.query);
      }
      if (this.billApprove == "error") {
        this.$swal.fire({
          text: "Request has not been approved!",
          icon: "error",
          timer: 10000,
        });
      }
      this.resetBillApprove();
    },
    billCancel: function () {
      if (this.billCancel == "success") {
        this.$swal.fire({
          text: "Request has been canceled!",
          icon: "success",
          timer: 10000,
        });
        this.fetchAllBills(this.query);
      }
      if (this.billCancel == "error") {
        this.$swal.fire({
          text: "Request has not been canceled!",
          icon: "error",
          timer: 10000,
        });
      }
      this.resetBillCancel();
    },
    billsPaginatedData: function () {
      let params = this.$route.query;

      // console.log(this.$store.state.bills.billsPaginatedData.data);
      // if (params.status == 1) {
      //   const con = this.billsPaginatedData.data.filter(
      //     ({ status }) => status === 1
      //   );
      //   this.$store.state.bills.billsPaginatedData.data = con;
      // }

      // if (params.status == 2) {
      //   this.$store.state.bills.billsPaginatedData.data =
      //     this.billsPaginatedData.data;
      // }

      if (params.status == 1) {
        const con = this.billsPaginatedData.data;
        this.$store.state.bills.billsPaginatedData.data = con;
      }
      // if (params.status == 3) {
      //   const con = this.billsPaginatedData.data;
      //   this.$store.state.bills.billsPaginatedData.data = con;
      // }
      // if (params.status == 1) {
      //   const con = this.billsPaginatedData.data.filter(
      //     ({ status }) => status === 1
      //   );
      //   this.$store.state.bills.billsPaginatedData.data = con.filter(
      //     ({ deadline_to }) => new Date(deadline_to) > new Date()
      //   );
      // }
      // if (params.status == 6) {
      //   var date = new Date(); // Now
      //   date.setDate(date.getDate() + 30);
      //   const con = this.billsPaginatedData.data.filter(
      //     ({ deadline_to }) => new Date(deadline_to) > new Date()
      //   );
      //   this.$store.state.bills.billsPaginatedData.data = con.filter(
      //     ({ deadline_to }) => new Date(deadline_to) < date
      //   );
      // }
      if (params.status == 7) {
        const con = this.billsPaginatedData.data;
        this.$store.state.bills.billsPaginatedData.data = con.filter(
          ({ deadline_to }) => new Date(deadline_to) < new Date()
        );
      }
    },
  },
  created() {
    this.checkIfUserHasPermissionToShowAll();
    this.checkIfUserHasPermissionToShow();
    this.checkIfUserHasPermissionToAdd();
    this.checkIfUserHasPermissionToRequest();
    this.checkIfUserHasPermissionToEdit();
    this.checkIfUserHasPermissionToDelete();
    this.checkIfUserHasPermissionToApprove();
    this.checkIfUserHasPermissionToPrint();
    this.checkIfUserHasPermissionToBillAttachments();
    if (this.canShowAll) {
      // let params = this.$route.query;
      // if (params.status) {
      //   this.fetchAllBills(this.getStatusId());
      // }
      this.fetchAllBills(this.getRouteQuery());
      // this.fetchAllBillTypes(this.getRouteQuery());
      // this.cE = false;
    }
  },
};
