<content-header>
  <template #title>
    <h1 class="m-0">Faturat</h1>
  </template>
  <template #breadcrumb>
    <breadcrumb-item>
      <router-link to="/admin"> Dashboard </router-link>
    </breadcrumb-item>
    <breadcrumb-item :active="true"> Faturat </breadcrumb-item>
  </template>
</content-header>

<page-content>
  <template #cardTitle> Të gjita Faturat </template>
  <template #cardTools>
    <app-button
      v-if="canAdd"
      @click="showAddModal()"
      type="button"
      class="btn-outline-primary btn-sm pl-3 pr-3 mr-2"
      >Shto faturë të re
    </app-button>
    <!-- <app-button
      v-if="canApprove"
      @click="showInProgressBills()"
      type="button"
      class="btn-outline-warning btn-sm pl-3 pr-3 mr-2"
      >Shfaq faturat në proces
    </app-button>
    <app-button
      v-if="canApprove"
      @click="showArchivedBills()"
      type="button"
      class="btn-outline-success btn-sm pl-3 pr-3 mr-2"
      >Shfaq faturat e aprovuara
    </app-button> -->

    <!-- <img
      v-if="canShow"
      @click="showAllBillsss()"
      class="svg_icon info ml-2"
      src="/img/refresh.svg"
    /> -->
    <app-button
      type="button"
      class="btn-sm pl-3 pr-3"
      :class="{ 'btn-outline-primary': search == false, 'btn-primary': search == true }"
      style="min-width: 250px"
      icon="fa-search"
      icon_size="fa-sm"
      @click="showSearch"
      >Search
    </app-button>
  </template>
  <template #cardBody>
    <div v-if="search">
      <!-- <h3 class="text-center mt-4 mb-3">Search</h3> -->
      <div class="row search1" style="padding: 20px;">
        <div class="col-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Data nga:</label>
                <flat-pickr
                  v-model="query.search.start_date"
                  :config="config"
                  class="form-control"
                  placeholder="Select date"
                  name="deadline_from"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Data deri:</label>
                <flat-pickr
                  v-model="query.search.end_date"
                  :config="config"
                  class="form-control"
                  placeholder="Select date"
                  name="deadline_to"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group form-group-custom">
                <label for="">Status:</label>
                <VueMultiselect
                  v-model="query.search.status"
                  :options="searchStatusList"
                  placeholder="Statusi i fatures"
                  label="name"
                  track-by="id"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group form-group-custom">
                <label for="supplier">Furnitori:</label>
                <VueMultiselect
                  style="padding: 0px"
                  v-model="searchSupplier"
                  :options="supplierList"
                  placeholder="Zgjedh furnitorin"
                  label="name"
                  track-by="id"                  
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">Fjalet kyce:</label>
                <div class="input-group">
                  <input
                    v-model="query.search.text"
                    @keyup.enter="onSubmitSearch"
                    type="search"
                    class="form-control"
                    placeholder="Shkruani fjalet kyce"
                  />
                 
                </div>
              </div>
            </div>
            <div class="col-md-2" >
              <div class="form-group">
                <label for="">Kerko:</label>
                <div class="input-group-append">
                  <app-button style="width: 100%"
                    @click="onSubmitSearch"
                    type="submit"
                    class="btn-default btn-outline-primary"
                    >Search
                  </app-button>
                </div>
                </div>
                </div>
            <div class="col-md-1">
              <div class="form-group">
                <label for="">Eksporto ne exel</label>
                <div class="input-group">
                  <app-button style="width: 100%"
                    class="btn btn-outline-primary"
                    @click="exportOnExcel"
                    >Excel
                  </app-button>
                </div>
              </div>
            </div>
            <div v-if="canPrintAll">


              <div class="col-md-1">
                <div class="form-group">
                  <label for="">Printo</label>
                  <div class="input-group">
                    <app-button
                    v-if="canPrintAll"
                    type="button"
                      class="button btn-outline-danger"
                      @click="PDF"
                      >Printo
                    </app-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- <app-loader v-if="isLoadingAll && canShowAll"></app-loader> -->
    <table
      v-if="!isLoadingAll && billsPaginatedData"
      class="table table-striped table-hover1 datatable table-borderless"
    >
      <thead>
        <tr>
          <th style="width: 10px">#</th>
          <th>LLoji</th>
          <th>Vlera</th>
          <th>Nr i faturës</th>
          <th>Departamenti</th>
          <th>U pranua</th>
          <th>Furnitori</th>
          <th>Krijuar nga</th>
          <th>Data</th>
          <th>Step</th>
          <th>Status</th>
          <th v-if="canApprove">Approvo</th>
          <th style="width: 140px">Tjera</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in billsPaginatedData.data" :key="index">

          <td>{{ ((query.page * 10) - 10) + (index + 1) }}</td>
          <!-- <td>{{ stringifyItem(item) }}</td> -->
          <td>{{ item.type }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.bill_no }}</td>
          <td>{{ item.assigned_dep_id }}</td>
          <td> {{ item.updated_by.first_name }} {{ item.updated_by.last_name }}</td>
          <td>{{ item.supplier }} </td>
          <td>
            {{ item.created_by.first_name }} {{ item.created_by.last_name }}
          </td>
          <td>{{ new Date(item.created_at).toLocaleString() }}</td>
          <td>
            <span class="badge badge-success badge-pill">
              Step {{ item.step }}
            </span>
          </td>
          <td>
            <span :style="getStatusStyle(item.status)">{{ checkBillStatus(item.status) }}</span>
          </td>
          <td v-if="canApproveUser(item.status, item.assigned_dep_id)">
            <div
              v-if="(item.status === 1 || item.status === 2) && canApprove"
              style="font-size: 1.1rem"
            >
              <i
                class="far fa-check-circle fa-lg success"
                @click="onApprove(item.id, item.step)"
              ></i>
              <i
                class="far fa-times-circle fa-lg pl-2 delete"
                @click="onCancel(item.id, item.step)"
              ></i>
            </div>
          </td>       
          <td v-else-if="canApprove"></td>
          <td class="more_actions">
            <img
              v-if="canShow"
              @click="showInfoModal(item.id)"
              class="svg_icon info ml-2"
              src="/img/info.svg"
            />
            <img
              v-if="canEdit && (item.status === 1)"
              @click="showEditModal(item.id)"
              class="svg_icon edit ml-2"
              src="/img/edit1.svg"
            />
            <img
              v-if="canDelete  && (item.status === 1)"
              @click="showDeleteModal(item.id)"
              class="svg_icon delete ml-2"
              src="/img/delete.svg"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="!isLoadingAll && billsPaginatedData.data.length >= 1"
      class="vertical-center mt-2 mb-2 ml-3 row"

   >
      <v-pagination
        v-model="query.page"
        :pages="billsPaginatedData.meta.last_page"
        :range-size="2"
        active-color="#DCEDFF"
        @update:modelValue="getResults"
      />
    </div>
    <div v-if="billsPaginatedData">
      <p
        v-if="billsPaginatedData.data.length == 0"
        class="text-black text-center m-5"
      >
        Nuk u gjet asgje. Provoni filtrat tek: Search
      </p>
    </div>
    <div v-if="!canShowAll">
      <p class="text-black text-center m-5">Nuk keni autorizim!</p>
    </div>
  </template>
</page-content>

<teleport to="body">
  <!-- create a bill -->
  <div
    v-if="modalAddActive"
    @click.stop=""
    class="modal fade"
    :class="{ 'show': modalAddActive, 'showAddModal': modalAddActive }"
    id="modal-add"
  >
    <div class="modal-dialog" style="max-width: 50%">
      <div @click.stop="modalContent" class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Shto fature</h4>
          <app-button
            @click="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </app-button>
        </div>
        <div
          class="modal-body p-0"
          style="max-height: calc(100vh - 220px); overflow-y: auto"
        >
          <table
            v-if="!isLoading && newBill"
            class="table table-hover table-borderless table-striped p-0"
          >
            <thead>
              <tr>
                <th>Emertimi</th>
                <th>Pershkrimi</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Furnitori:*</td>
                <td>
                  <VueMultiselect
                    v-model="selecteNewSuppliers"
                    :options="supplierList"
                    tag-placeholder="Zgjedh furnitorin"
                    placeholder="Shkruani qe te kerkoni furnitorin e deshiruar"
                    label="name"
                    track-by="id"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'id') }"
                    aria-describedby="supplier-id-error"
                    @keyup.enter="onSubmitAdd"
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'id')"
                    id="supplier-id-error"
                    class="error invalid-feedback"
                  >
                    {{ errors['id'][0] }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Numri fatures:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'bill_no', this.isCreating) }"
                    name="bill_no"
                    placeholder="Numri i fatures"
                    @input="addBillInputAction"
                    :value="newBill.bill_no"
                    aria-describedby="add-bill_no-error"
                    ref="bill_no"
                    required
                    @keyup.enter="onSubmitAdd"
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'bill_no', this.isCreating)"
                    id="add-bill_no-error"
                    class="error invalid-feedback"
                    >{{ errors['bill_no'][0] }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Lloji:*</td>
                <td class="radioBtn">
                  <input type="radio" name="type" value="Mall" id="Mall" />
                  <label for="Mall" class="radio-inline">Mall</label>
                  <label for="Sherbim" class="radio-inline second">
                    <input
                      type="radio"
                      name="type"
                      value="Sherbim"
                      id="Sherbim"
                    />
                    Sherbim
                  </label>
                  <span
                    v-if="errors && errors.type"
                    id="add-type-error"
                    class="error invalid-feedback"
                  >
                    {{ errors.type[0] }}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Vlera:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'value' , this.isCreating) }"
                    name="value"
                    placeholder="Vlera"
                    @input="addBillInputAction"
                    :value="newBill.value"
                    aria-describedby="add-value-error"
                    required
                    @keyup.enter="onSubmitAdd"
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'value', this.isCreating)"
                    id="add-value-error"
                    class="error invalid-feedback"
                    >{{ errors['value'][0] }}</span
                  >
                </td>
              </tr>

              <tr>
                <td>Pershkrimi:</td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    name="description"
                    placeholder="Pershkrimi ..."
                    @input="addBillInputAction"
                    :value="newBill.description"
                    ref="description"
                    @keyup.enter="onSubmitAdd"
                  ></textarea>
                </td>
              </tr>

              <!-- <tr>
                <td>Porositi:*</td>
                <td>
                  <input
                    type="text"
                    name="ordered"
                    class="form-control"
                    id="first_name"
                    placeholder="Emri"
                    :value="user.first_name"
                    @input="first_name = $event.target.value"
                    style="background-color: #dee2e6"
                    readonly
                  />
                </td>
              </tr> -->

              <tr>
                <td>Departmenti:*</td>
                <td>
                  <VueMultiselect
                    v-model="selecteNewDepartments"
                    :options="departmentList"
                    tag-placeholder="Zgjedh departamentin"
                    placeholder="Shkruani qe te kerkoni departmentin e deshiruar"
                    label="name"
                    track-by="id"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'department_id') }"
                    aria-describedby="department-id-error"
                    @keyup.enter="onSubmitAdd"
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'department_id')"
                    id="department-id-error"
                    class="error invalid-feedback"
                  >
                    {{ errors['department_id'][0] }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Komenti:</td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    name="comment"
                    placeholder="Enter a comment ..."
                    @input="addBillInputAction"
                    :value="newBill.comment"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>Shto Dokument:</td>
                <td>
                  <small style="color: red;">Formatet e lejuara: csv, xls,
                    xlsx,
                    doc,
                    docx,
                    pdf,
                    jpg,
                    png,
                    jpeg</small>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="attachment"
                        name="files"
                        ref="filesInsert"
                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'files', this.isCreating) }"
                        aria-describedby="files-error"
                        multiple="multiple"
                        @change="onFileInsert"
                      />
                      <label class="custom-file-label" for="attachment"
                        >{{ this.labelFilesNameInsert }}</label
                      >
                    </div>
                    <span
                      v-if="checkIfFieldHasErrors(errors, 'files', this.isCreating)"
                      id="files-error"
                      class="error invalid-feedback"
                      >{{ errors['files'][0] }}</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-loader v-if="isLoading"></app-loader>
        </div>
        <div class="modal-footer justify-content-between">
          <app-button
            @click="closeModal"
            type="button"
            class="btn-default"
            data-dismiss="modal"
            >Close
          </app-button>
          <app-button
            v-if="!isCreating"
            @click="onSubmitAdd"
            type="button"
            class="btn-primary"
            >Save
          </app-button>
          <app-button
            v-if="isCreating"
            class="btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving
          </app-button>
        </div>
      </div>
    </div>
  </div>
  <!-- info modal -->
  <div
    v-if="bill && modalInfoActive"
    @click.stop=""
    class="modal fade"
    :class="{ 'show': modalInfoActive, 'showInfoModal': modalInfoActive }"
    id="modal-info"
  >
    <div class="modal-dialog" style="max-width: 50%">
      <div @click.stop="modalContent" class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Detajet e faturës</h4>
          <button
            @click="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body p-0"
          style="max-height: calc(100vh - 220px); overflow-y: auto"
        >
       
          <table
            v-if="!isLoading && billsPaginatedData"
            id="printTable"
            class="table table-hover table-borderless table-striped p-0"
          >
            <div class=""  style="width: 100%;">
              <img  style="width: 50%;" src="../../../public/img/Picture3.svg" />
            </div>
            <h3 style="text-align: center;"> FORMULARI I APROVIMIT TË FATURAVE </h3>
            <thead>
              <tr>
                <th>Emërtimi</th>
                <th>Përshkrimi</th>
              </tr>
            </thead>
         
            <tbody>
              <tr style="border: 1px solid black;">
                <td>Id:</td>
                <td>{{ bill.id }}</td>
              </tr>
              <tr style="border: 1px solid black;">
                <td>Furnitori:</td>
                <td>{{ bill.supplier }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Numri i faturës:</td>
                <td>{{ bill.bill_no }}</td>
              </tr>
              <!-- <tr  style="border: 1px solid black;">
                <td>Emri:</td>
                <td>{{ bill.name }}</td>
              </tr> -->
              <tr  style="border: 1px solid black;">
                <td>Lloji:</td>
                <td>{{ bill.type }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Vlera:</td>
                <td>{{ bill.value }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Përshrkimi:</td>
                <td>{{ bill.description }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Për Departamentin:</td>
                <td>{{ bill.assigned_dep_id }}</td>
              </tr>

              <tr  style="border: 1px solid black;">
                <td>Pranoi nga departamenti:</td>
                <td>
                  {{ bill.updated_by.first_name }} {{ bill.updated_by.last_name
                  }}
                </td>
              </tr>

              <tr  style="border: 1px solid black;">
                <td>Komenti:</td>
                <td>{{ bill.name }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Krijuar nga:</td>
                <td>
                  {{ bill.created_by.first_name }} {{ bill.created_by.last_name
                  }}
                </td>
              </tr>

              <tr  style="border: 1px solid black;">
                <td>Step:</td>
                <td v-if="bill.step">
                  <span class="badge badge-warning">Step {{ bill.step }}</span>
                </td>
                <td v-else>
                  <span class="badge badge-warning">No step</span>
                </td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Status:</td>
                <td>
                  <span class="badge badge-info">
                    {{ checkBillStatus(bill.status) }}
                  </span>
                </td>
              </tr>

              <tr style="height: auto; border: 1px solid black;" v-if="bill.comments">
                <td>Komentet:</td>
                <td v-if="bill.comments.length >= 1" style="max-width: 400px">
                  <div v-for="comment in bill.comments" class="mb-1">
                    <span
                      v-if="comment.steps !== null"
                      class="badge badge-warning"
                      >Step {{ comment.steps }}</span
                    >
                    <!-- <span
                      v-if="comment.canceled == 1"
                      class="badge badge-danger ml-1"
                      >Canceled</span
                    > -->
                    <p class="mb-0">{{ comment.name }}</p>
                    -<small>{{ comment.user_id }}</small>
                  </div>
                </td>
                <td v-else>No comments</td>
              </tr>

              <tr  style="border: 1px solid black;">
                <td>Dokumentet e bashkangjitura:</td>
                <td>
                  <div v-for="(file, index) in bill.files" :key="file.id">
                    <a
                      href="#"
                      @click="getBillAttachment(file)"
                      class="btn-link text-secondary"
                      ><i
                        class="far fa-fw"
                        :class="showAttachmentIcon(file.file_extension)"
                      ></i
                      >{{ file.file_name}}
                    </a>
                  </div>
                </td>
              </tr>

              <tr  style="border: 1px solid black;">
                <td>Së fundmi modifikuar më:</td>
                <td>{{ new Date(bill.updated_at).toLocaleString() }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Krijuar më:</td>
                <td>{{ new Date(bill.created_at).toLocaleString() }}</td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Aprovuar herën e parë më:</td>
                <td>
                  <template v-if="bill.approved_first">
                    {{ new Date(bill.approved_first).toLocaleString() }}
                  </template>
                  <template v-else> Nuk ka datë </template>
                </td>
              </tr>
              <tr  style="border: 1px solid black;">
                <td>Aprovuar herën e dytë më:</td>
                <td>
                  <template v-if="bill.approved_second">
                    {{ new Date(bill.approved_second).toLocaleString() }}
                  </template>
                  <template v-else> Nuk ka datë </template>
                </td>
              </tr>
            </tbody>
          </table>
          <app-loader v-if="isLoading"></app-loader>
        </div>
        <div class="modal-footer justify-content-between">
          <app-button
            @click="closeModal"
            type="button"
            class="btn-default"
            data-dismiss="modal"
            >Close
          </app-button>
          <div>
            <div class="d-flex" v-if="canApproveUser(bill.status, bill.assigned_dep_id)">
              <div
                class="mr-2"
                v-if="(bill.status === 1 || bill.status === 2) && canApprove"
              >
                <app-button
                  @click="onApprove(bill.id, bill.step)"
                  type="button"
                  class="btn-default success"
                  >Aprovo</app-button
                >
                <app-button
                  @click="onCancel(bill.id, bill.step)"
                  type="button"
                  class="btn-default delete"
                  >Refuzo</app-button
                >
              </div>
            </div>
            <div v-else-if="canApprove"></div>
          </div>
          <div
          class="mr-2"
          v-if="bill.status >= 3 && canApprove"
        >
          <app-button @click="printData" type="button" class="btn-default"
            >Print
          </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- edit bill -->

  <div
    v-if="modalEditActive"
    @click.stop=""
    class="modal fade"
    :class="{ 'show': modalEditActive, 'showEditModal': modalEditActive }"
    id="modal-edit"
  >
    <div class="modal-dialog" style="max-width: 50%">
      <div @click.stop="modalContent" class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Ndrysho fature</h4>
          <app-button
            @click="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </app-button>
        </div>
        <div
          class="modal-body p-0"
          style="max-height: calc(100vh - 220px); overflow-y: auto"
        >
          <table
            v-if="!isLoading"
            class="table table-hover table-borderless table-striped p-0"
          >
            <thead>
              <tr>
                <th>Emertimi</th>
                <th>Pershkrimi</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Furnitori:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'supplier', this.isUpdating) }"
                    name="supplier"
                    :value="bill.supplier"
                    @input="updateBillInput"
                    aria-describedby="supplier-error"
                    readonly
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'supplier')"
                    id="supplier-error"
                    class="error invalid-feedback"
                    >{{ errors['supplier'][0] }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Numri i fatures:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'bill_no', this.isUpdating) }"
                    name="bill_no"
                    :value="bill.bill_no"
                    @input="updateBillInput"
                    aria-describedby="bill_no-error"
                    required
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'bill_no')"
                    id="bill_no-error"
                    class="error invalid-feedback"
                    >{{ errors['bill_no'][0] }}</span
                  >
                </td>
              </tr>

              <tr>
                <td>Lloji:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="type"
                    :value="bill.type"
                    style="background-color: #dee2e6"
                    readonly
                  />
                </td>
              </tr>

              <tr>
                <td>Vlera:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'value', this.isUpdating) }"
                    name="value"
                    :value="bill.value"
                    @input="updateBillInput"
                    @keyup.enter="onSubmitEdit"
                    aria-describedby="value-error"
                    required
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'value')"
                    id="value-error"
                    class="error invalid-feedback"
                    >{{ errors['value'][0] }}</span
                  >
                </td>
              </tr>

              <tr>
                <td>Pershkrimi:*</td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'description') }"
                    name="description"
                    :value="bill.description"
                    placeholder="Pershkrimi ..."
                    @input="updateBillInput"
                    aria-describedby="description-error"
                    style="background-color: #dee2e6"
                    readonly
                  ></textarea>
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'description')"
                    id="description-error"
                    class="error invalid-feedback"
                    >{{ errors['description'][0] }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Department:*</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'assigned_dep_id', this.isUpdating) }"
                    name="assigned_dep_id"
                    :value="bill.assigned_dep_id"
                    @input="updateBillInput"
                    aria-describedby="assigned_dep_id-error"
                    readonly
                  />
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'assigned_dep_id')"
                    id="assigned_dep_id-error"
                    class="error invalid-feedback"
                    >{{ errors['assigned_dep_id'][0] }}</span
                  >
                </td>
              </tr>

              <tr>
                <td>Dokumentet e ngarkuara:</td>
                <td>
                  <ul class="list-unstyled mb-1">
                    <div class="row">
                      <p class="text-sm mb-0 ml-2">Icon</p>
                      <p class="text-sm mb-0 ml-1">Files</p>
                      <p class="text-sm mb-0 ml-auto">Delete</p>
                    </div>
                    <!-- tjetra -->
                    <li>
                      <div v-for="(file, index) in bill.files" :key="file.id">
                        <a
                          href="#"
                          @click="getBillAttachment(file)"
                          class="btn-link text-secondary"
                          ><i
                            class="far fa-fw"
                            :class="showAttachmentIcon(file.file_extension)"
                          ></i>
                          {{ file.file_name }}</a
                        >
                        <i
                          class="fas fa-times align-items-end delete"
                          @click="onDeleteFile(file)"
                        >
                        </i>
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Shto dokument:</td>
                <td>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="attachment"
                        name="files"
                        ref="filesInsert"
                        :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'files', this.isCreating) }"
                        aria-describedby="files-error"
                        multiple="multiple"
                        @change="onFileInsert"
                      />
                      <label class="custom-file-label" for="attachment"
                        >{{ this.labelFilesNameInsert }}</label
                      >
                    </div>
                    <span
                      v-if="checkIfFieldHasErrors(errors, 'files', this.isCreating)"
                      id="files-error"
                      class="error invalid-feedback"
                      >{{ errors['files'][0] }}</span
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td>Shto ne koment arsyen e ndryshimit:</td>
                <td>
                  <textarea
                    class="form-control"
                    :class="{ 'is-invalid': checkIfFieldHasErrors(errors, 'comment', this.isUpdating) }"
                    rows="3"
                    name="comment"
                    placeholder="Shto koment ..."
                    @input="updateBillInput"
                  ></textarea>
                  <span
                    v-if="checkIfFieldHasErrors(errors, 'comment', this.isUpdating)"
                    id="comment-error"
                    class="error invalid-feedback"
                    >{{ errors['comment'][0] }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <app-loader v-if="isLoading"></app-loader>
        </div>
        <div class="modal-footer justify-content-between">
          <app-button
            @click="closeModal"
            type="button"
            class="btn-default"
            data-dismiss="modal"
            >Close
          </app-button>
          <app-button
            v-if="!isUpdating"
            @click="onSubmitEdit"
            type="button"
            class="btn-primary"
            >Save
          </app-button>
          <app-button
            v-if="isUpdating"
            class="btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving
          </app-button>
        </div>
      </div>
    </div>
  </div>
</teleport>
